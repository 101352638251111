(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/svsuid/assets/javascripts/svsuid.js') >= 0) return;  svs.modules.push('/components/utils/svsuid/assets/javascripts/svsuid.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const randomBytes = length => {
  const randomBytesString = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    randomBytesString[i] = Math.floor(Math.random() * 256);
  }
  return randomBytesString;
};
const getRandomItem = charsArray => {
  const randomIndex = Math.floor(Math.random() * charsArray.length);
  return charsArray[randomIndex];
};

const svsuid = () => {
  const uuidArray = [...randomBytes(16)].map(byte => byte.toString(16).padStart(2, '0'));

  uuidArray[6] = "4".concat(uuidArray[6][1]);
  uuidArray[8] = "".concat(getRandomItem(['8', '9', 'a', 'b'])).concat(uuidArray[8][1]);

  const uuid = uuidArray.join('');

  return "".concat(uuid.slice(0, 8), "-").concat(uuid.slice(8, 12), "-").concat(uuid.slice(12, 16), "-") + "".concat(uuid.slice(16, 20), "-").concat(uuid.slice(20));
};

svs.utils = _objectSpread(_objectSpread({}, svs.utils), {}, {
  svsuid
}) || {
  svsuid
};

if (svs.isServer) {
  module.exports = svs.utils.svsuid;
}

 })(window);